<script setup>
import { useFavoriteStore } from '~/stores/favorite.store';

const favoriteStore = useFavoriteStore();
const userFavorites = computed(() => favoriteStore.userFavoriteSearchGetter());

const fetchUserFavoriteSearches = async () => {
    await favoriteStore.fetchUserFavoriteSearches().catch(err => userFavorites.value = [])
}

onMounted(async () => {
    await fetchUserFavoriteSearches();
});
</script>
<template>
  <div class="ms-auto">
    <ul class="flex items-center">
      <li class="text-neutral-400 hover:text-indigo-900 lg:px-3 sm:px-1.5 md:px-2">
        <w-drop-down-with-hover class="cursor-pointer" v-if="userFavorites.length">
            <template #default >
                <p>{{ $t('client.favorite_searches') }}</p> 
						</template>
            <template #item>
                <li v-for="(item, sc) in userFavorites" :key="sc" class="w-full text-left  hover:bg-neutral-100 pl-2 rounded-md">
                  <NuxtLink :to="item.url" class="w-full block px-2 py-2">{{ item.name }}</NuxtLink>
                </li>
             </template>
        </w-drop-down-with-hover>
      </li>
    </ul>
  </div>
</template>